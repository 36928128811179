<template>
  <div>
    <v-card v-if="user.stripeAccountExists && !stripeLoading && !(numCustomers > maxCustomers)">
      <v-card-title>
        There was a problem with your account, please update your billing information.
      </v-card-title>
      <v-card-text>
        <p v-if="errorMessage">
          {{ errorMessage }}
        </p>
        <v-btn v-if="!errorMessage" @click="toCustomerPortal">
          Manage Billing
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card v-if="!user.stripeAccountExists && !stripeLoading || user.stripeAccountExists && numCustomers > maxCustomers && !stripeLoading" >
      <v-card-title>
        Get Started with CertainFee
      </v-card-title>
      <v-card-text>
        <v-radio-group
          v-model="selected"
          row
        >
          <v-radio :disabled="priceId.maxCustomers < numCustomers" v-for="priceId in priceIds" :label="priceId.title" :key="priceId.title">
            {{ priceId }}
          </v-radio>
        </v-radio-group>
        <p v-if="errorMessage">
          {{ errorMessage }}
        </p>
        <v-btn v-if="!errorMessage && selected > 0" @click="createStripeSession">
          Set Up Billing
        </v-btn>
        <v-btn v-if="!errorMessage && selected === 0" @click="createSubscriptionFreeTrial">
          Start Free Trial
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card class="text-center pa-5" v-if="stripeLoading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-card>
    <br />

  </div>
</template>

<script>
import { DB } from '../firebase/db'
import { Functions } from '../firebase/functions'
import { mapState } from 'vuex'

const createCheckoutSession = Functions.httpsCallable('createCheckoutSession')
const accessCustomerPortal = Functions.httpsCallable('accessCustomerPortal')
const recreateStripeCustomer = Functions.httpsCallable('recreateStripeCustomer')
const createSubscriptionFreeTrial = Functions.httpsCallable('createSubscriptionFreeTrial')

export default {
  props: ['numCustomers'],
  data() {
    return {
      customer: '',
      customer_email: '',
      mode: 'subsc',
      sessionId: '',
      errorMessage: null,
      stripe: '',
      selected: undefined,
      priceIds: [{
        maxCustomers: 5,
        title: 'Free Trial 1-5 Customers',
        priceId: 'price_1Mb4XGD7TGVtVh1bRdiOVhqq',
      },
      {
        maxCustomers: 100,
        title: '1-100 Customers',
        priceId: 'price_1LpZZ9D7TGVtVh1bzVkMRNdT',
      },
      {
        maxCustomers: 250,
        title: '101-250 Customers',
        priceId: 'price_1LqDcPD7TGVtVh1bfA4p80tj',
      },
      {
        maxCustomers: 500,
        title: '251-500 Customers',
        priceId: 'price_1LqDinD7TGVtVh1bAEZBh5SO',
      },
      {
        maxCustomers: 750,
        title: '501-750 Customers',
        priceId: 'price_1LqDkZD7TGVtVh1bO5sLies2',
      },
      {
        maxCustomers: 1000,
        title: '751-1000 Customers',
        priceId: 'price_1LqDlpD7TGVtVh1bnL0Bdn0o',
      }],
      successUrl: 'https://certainfee.com/',
      cancelUrl: 'https://certainfee.com/',
    }
  },
  computed: {
    ...mapState(['user', 'stripeLoading', 'maxCustomers']),
    uid: function() {
      return this.$store.state.user.uid
    },
    
    email: function() {
      return this.$store.state.user.email
    },
  },
  methods: {
    async createStripeSession() {
      this.customer = await DB.collection('user')
        .doc(this.uid)
        .collection('stripe')
        .doc('stripe_customer')
        .get()

      if(!this.customer.exists) {
        await recreateStripeCustomer(this.user)

        this.customer = await DB.collection('user')
          .doc(this.uid)
          .collection('stripe')
          .doc('stripe_customer')
          .get()
      }
        
      createCheckoutSession({
        priceId: this.priceIds[this.selected].priceId,
        successUrl: this.successUrl,
        cancelUrl: this.cancelUrl,
        customer: this.customer.data().stripe_customer_id
      })
      .then((response) => {
        this.sessionId = response.data.sessionId
        this.redirectToCheckout()
      })
      .catch(function(error) {
        console.log(error)
      })
    },

    async createSubscriptionFreeTrial() {
      this.customer = await DB.collection('user')
        .doc(this.uid)
        .collection('stripe')
        .doc('stripe_customer')
        .get()

      console.log(this.customer.data().stripe_customer_id)

      if(!this.customer.exists) {
        console.log('Customer does not exist.')

        await recreateStripeCustomer(this.user)

        this.customer = await DB.collection('user')
          .doc(this.uid)
          .collection('stripe')
          .doc('stripe_customer')
          .get()
      }

      let res = await createSubscriptionFreeTrial({
        customer: this.customer.data().stripe_customer_id
      })

      this.$store.dispatch('loggedIn')
      
      console.log(res)
    },
    
    configureStripe() {
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_LIVE_Public_Key)
    },

    redirectToCheckout() {
      this.stripe
        .redirectToCheckout({
          sessionId: this.sessionId,
        })
        .then((result) => {
          if (result.error) {
            this.errorMessage = result.error.message
          }
        })
        .catch(err => console.log(err))
    },
    
    async toCustomerPortal() {
      if(!this.customer) {
        this.customer = await DB.collection('user')
          .doc(this.uid)
          .collection('stripe')
          .doc('stripe_customer')
          .get()
      }
      this.customer.data().stripe_customer_id
      accessCustomerPortal({
        customer: this.customer.data().stripe_customer_id,
        returnUrl: this.successUrl,
      }).then((res) => {
        location.href = res.data.url
      })
    }
  },

  mounted() {
    this.configureStripe()
  },
}
</script>

<style scoped></style>