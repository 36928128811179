<template>
  <v-container 
    style="
      padding: 0;
      margin-top: 122px;
    "
    fluid
  >
    <status-bar></status-bar>

    <v-container>
      <v-row>
        <v-col v-if="!user.emailVerified" cols="12">
          <verify-email></verify-email>
        </v-col>

        <v-col v-if="!maxCustomers || !(numCustomers <= maxCustomers)" cols="12">
          <bill-account :num-customers="numCustomers"></bill-account>
        </v-col>
        
        <v-col v-if="maxCustomers && numCustomers <= maxCustomers" cols="12">
          <v-card>
            
            <v-card-title class="px-9 pt-9">
              Level-Pay Management
            </v-card-title>
            
            <v-card-text>
              <div class="px-5">
                Manage Level-Pay Customers with a few simple forms. Provide your company information and customer list and the rest is just a click away.
              </div>
              <v-btn class="ma-5" :to="{ name: 'GetStarted' }">1) Set Up Company Information</v-btn>

              <v-btn v-if="!userData.slpAgreement" disabled class="ma-5" :to="{ name: 'UploadCustomers' }">2) Manage Your Customers</v-btn>
              <v-btn v-else class="ma-5" :to="{ name: 'UploadCustomers' }">2) Manage Your Customers</v-btn>

              <v-btn v-if="userData.slpAgreement && userData.customerList" class="ma-5" :to="{ name: 'SLPAgreement' }">3) Manage Your Messaging</v-btn>
              <v-btn v-else class="ma-5" disabled :to="{ name: 'SLPAgreement' }">3) Manage Your Messaging</v-btn>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <Footer></Footer>

  </v-container>
</template>

<script>
import VerifyEmail from '@/components/VerifyEmail.vue'
import StatusBar from '@/components/StatusBar.vue'
import Footer from '@/components/Footer.vue'
import BillAccount from '@/components/BillAccount.vue'

import { DB } from '../firebase/db'

import { mapState } from 'vuex'

export default {
  name: 'TenantLanding',
  computed: {
    ...mapState(['user', 'maxCustomers']),
  },
  data() {
    return {
      userData: {},
      numCustomers: 0,
    }
  },
  methods: {
    signOut() {
      this.$store.dispatch('signOut')
    },
    getUserData() {
      // Get SLP Data here
      let customerAccountRef = DB.collection("user").doc(this.user.uid)

      customerAccountRef
        .get()
        .then((user) => {
          this.userData = user.data()
          this.numCustomers = user.data().customerList ? user.data().customerList.length : 0
          console.log('user.data().customerList:', user.data().customerList)
          console.log('this.numCustomers:', this.numCustomers)
        }).catch((err) => {
          console.log(err)
        })
    },    
  },
  mounted() {
    this.getUserData()
  },
  components: {
    VerifyEmail,
    StatusBar,
    Footer,
    BillAccount,
  },
}
</script>

<style scoped>

</style>