<template>
  <v-card>
    <v-card-title>Must Verify Email</v-card-title>
    <v-card-subtitle>Must verify email to gain access to CertainFee Application functionality.</v-card-subtitle>
    <v-card-text>
      <v-btn class="ma-5" @click="verifyUserEmail">Verify Email</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'VerifyEmail',
  data() {
    return {}
  },
  components: {},
  computed: {},
  methods: {
    verifyUserEmail() {
      this.$store.dispatch('sendEmailVerification')
    },
  },
}
</script>

<style scoped>

</style>